<template>
    <div class=WordSection1 style='layout-grid:18.0pt'>

        <p class=MsoNormal align=center style='margin-top:5.0pt;margin-right:0in;
margin-bottom:5.0pt;margin-left:0in;text-align:center;line-height:15.0pt'><b><span
                lang=ZH-TW style='font-size:20.0pt;font-family:DFKai-SB'>託運媒合服務條款</span></b></p>

        <p class=MsoNormal align=center style='margin-top:5.0pt;margin-right:0in;
margin-bottom:5.0pt;margin-left:0in;text-align:center;line-height:15.0pt'><b><span
                style='font-size:20.0pt;font-family:DFKai-SB'>&nbsp;</span></b></p>

        <p class=MsoNormal style='margin-top:5.0pt;margin-right:0in;margin-bottom:5.0pt;
margin-left:0in;line-height:15.0pt'><span lang=ZH-TW style='font-size:14.0pt;
font-family:DFKai-SB'>條款公告日：</span><span style='font-size:14.0pt;font-family:
DFKai-SB'>2022 <span lang=ZH-TW>年</span>01<span lang=ZH-TW>月</span>04<span
                lang=ZH-TW>日</span></span></p>

        <p class=MsoNormal style='margin-top:5.0pt;margin-right:0in;margin-bottom:5.0pt;
margin-left:0in;line-height:15.0pt'><span lang=ZH-TW style='font-size:14.0pt;
font-family:DFKai-SB'>媒合平台：<u>盒子彈科技股份有限公司</u></span><span style='font-size:
14.0pt;font-family:DFKai-SB'>(<span lang=ZH-TW>以下簡稱為</span>BIGBOX)&nbsp;</span></p>

        <p class=MsoNormal style='margin-top:5.0pt;margin-right:0in;margin-bottom:5.0pt;
margin-left:0in;line-height:15.0pt'><span lang=ZH-TW style='font-size:14.0pt;
font-family:DFKai-SB'>茲因</span><span style='font-size:14.0pt;font-family:DFKai-SB'>BIGBOX<span
                lang=ZH-TW>可提供多元機車及物品運送媒合平台服務（下稱</span>BIGBOX<span lang=ZH-TW>平台），託運人有意願透過</span>BIGBOX<span
                lang=ZH-TW>平台託運機車或物品，並同意遵守託運媒合服務條款。</span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:28.35pt;text-indent:-28.35pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>一、<span style='font:7.0pt "Times New Roman"'>
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>託運媒合服務條款（下稱「本條款」）約束託運人對
</span><span style='font-size:14.0pt;font-family:DFKai-SB'>BIGBOX<span
                lang=ZH-TW>官方網站及平台（以下簡稱為「</span>BIGBOX<span lang=ZH-TW>平台」）使用。請仔細審閱本條款。存取和使用本平台服務，即表示託運人已審閱、已理解並同意本條款（包括但不限於本條款、平台公告。）如果託運人不同意或不遵守本條款，請勿使用本平台。</span></span>
        </p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:28.35pt;text-indent:-28.35pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>二、<span style='font:7.0pt "Times New Roman"'>
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>此平台僅供</span><span
                style='font-size:14.0pt;font-family:DFKai-SB'> (1) <span lang=ZH-TW>年滿</span>20<span
                lang=ZH-TW>歲自然人，及</span> (2) <span lang=ZH-TW>公司法人使用。</span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:28.35pt;text-indent:-28.35pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>三、<span style='font:7.0pt "Times New Roman"'>
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>對於未滿</span><span
                style='font-size:14.0pt;font-family:DFKai-SB'>20<span lang=ZH-TW>歲或未成年之使用者，託運人必須徵得法定代理人、或監護人同意，法定代理人或監護人必須接受本條款並同意對託運人之行為承擔責任，並支付因託運人使用本平台及託運產生之任何費用。如果託運人沒有得到法定代理人或監護人之同意，必須立即停止使用本平台。</span></span>
        </p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:28.35pt;text-indent:-28.35pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>四、<span style='font:7.0pt "Times New Roman"'>
</span></span><span lang=ZH-TW
                    style='font-size:14.0pt;font-family:DFKai-SB'>本條款已由您即託運人詳細審閱完畢，託運人已充分了解本條款之各項條款，並同意遵守。</span><span
                style='font-size:14.0pt;font-family:DFKai-SB'>(<span lang=ZH-TW>依消費者保護法第</span>11<span
                lang=ZH-TW>條之</span>1<span lang=ZH-TW>規定，定型化契約訂立前應給予消費者</span><u>30<span
                lang=ZH-TW>日以內</span></u><span lang=ZH-TW>之合理契約審閱期間，如託運人為公司法人則不適用此規定</span>)<span
                lang=ZH-TW>。</span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:28.35pt;text-indent:-28.35pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>五、<span style='font:7.0pt "Times New Roman"'>
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>本條款每次變更時，將於</span><span
                style='font-size:14.0pt;font-family:DFKai-SB'>BIGBOX<span lang=ZH-TW>平台公告，託運人需定期閱讀本條款。自修訂條款發佈之日起繼續使用本平台，即視為託運人已同意最新之修訂條款。</span></span>
        </p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:28.35pt;text-indent:-28.35pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>六、<span style='font:7.0pt "Times New Roman"'>
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>根據託運人存取和使用的</span><span
                style='font-size:14.0pt;font-family:DFKai-SB'>BIGBOX<span lang=ZH-TW>平台，本條款構成託運人與</span>BIGBOX<span
                lang=ZH-TW>實體之間訂立之合約。</span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:28.35pt;text-indent:-28.35pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>七、<span style='font:7.0pt "Times New Roman"'>
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>媒合標的：機車及物品運送服務（方案、運送內容、費用、運送範圍、收件方式、運送須知、付款方式、退費機制、賠償、箱子折抵大型物件標準詳如</span><span
                style='font-size:14.0pt;font-family:DFKai-SB'>BIGBOX<span lang=ZH-TW>官方網站最新公告所示</span>)<span
                lang=ZH-TW>。</span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:28.35pt;text-indent:-28.35pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>八、<span style='font:7.0pt "Times New Roman"'>
</span></span><span style='font-size:14.0pt;font-family:DFKai-SB'>BIGBOX<span
                lang=ZH-TW>平台媒合託運人與運送人，以便託運人選擇多元機車及物品運送服務。當託運人使用</span>BIGBOX<span
                lang=ZH-TW>平台向運送人下達運送服務訂單（下稱「訂單」）時，</span>BIGBOX<span
                lang=ZH-TW>平台將協助處理訂單，並通知運送人向託運人履行訂單。</span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:28.35pt;text-indent:-28.35pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>九、<span style='font:7.0pt "Times New Roman"'>
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>使用</span><span
                style='font-size:14.0pt;font-family:DFKai-SB'>BIGBOX<span lang=ZH-TW>平台</span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>託運人使用
</span><span style='font-size:14.0pt;font-family:DFKai-SB'>BIGBOX <span
                lang=ZH-TW>平台時，會要求託運人提供個人資訊（包括但不限於有效的電子信箱、地址、手機號碼）。若要結算訂單，託運人可能需要提供匯款資訊。託運人使用 </span>BIGBOX<span
                lang=ZH-TW>平台下達所有訂單皆由託運人承擔全部履約責任。</span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>因託運人提供之資訊不完整、不正確或有缺失而導致出現問題的訂單，</span><span
                style='font-size:14.0pt;font-family:DFKai-SB'>BIGBOX <span lang=ZH-TW>概不負責。託運人有義務提供完整、真實之資訊，以便運送人處理訂單（包括但不限於託運人的取貨地址、收貨地址和聯繫方式）。</span></span>
        </p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>非本人使用</span><span
                style='font-size:14.0pt;font-family:DFKai-SB'>BIGBOX <span
                lang=ZH-TW>平台或託運人有違反本條款之任何活動或行為，或者濫用平台之行為，</span>BIGBOX<span
                lang=ZH-TW>有權限制、暫停或終止託運人使用平台之權利。 </span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:28.35pt;text-indent:-28.35pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>十、<span style='font:7.0pt "Times New Roman"'>
</span></span><span style='font-size:14.0pt;font-family:DFKai-SB'>BIGBOX<span
                lang=ZH-TW>平台禁止的活動：</span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>違反法規使用平台。</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>發佈任何侵害智慧財產權、隱私權或任何涉及不法之內容。</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>發佈非法、猥褻、誹謗、威脅、騷擾、辱罵、詆毀、仇恨性質或相類似之內容。</span>
        </p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>發佈網路霸凌之內容。</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>冒充他人發佈內容。</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>未經他人同意，蒐集有關他人的資訊，包括電子郵件地址。</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>騷擾、威脅、跟蹤或辱罵平台上之用戶。</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>鼓勵或幫助他人進行本條款之禁止活動。</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:28.35pt;text-indent:-28.35pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>十一、<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>媒合運送人：指加入</span><span
                style='font-size:14.0pt;font-family:DFKai-SB'>BIGBOX<span lang=ZH-TW>平台承攬運送機車或物品之自然人或法人。</span></span>
        </p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:28.35pt;text-indent:-28.35pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>十二、<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>訂單</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>託運人透過
</span><span style='font-size:14.0pt;font-family:DFKai-SB'>BIGBOX <span
                lang=ZH-TW>下單後，</span>BIGBOX <span lang=ZH-TW>會向託運人發送訂單細節。訂單細節包括但不限於服務費（即運送費用）、稅額、運送方案、收件方式、運送須知、運送日期、運送人聯絡方式等。</span></span>
        </p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:14.0pt;font-family:DFKai-SB'>BIGBOX<span
                lang=ZH-TW>和運送人對於託運人於標準方案以外所要求之指示，均不承擔任何責任。</span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:28.35pt;text-indent:-28.35pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>十三、<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>退款</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>僅在運送人尚未接受託運人之訂單時，託運人才可以取消訂單並全額退款。如果託運人在運送人接受訂單後仍決定取消訂單，即表示託運人瞭解運送人會向託運人收取訂金及服務費用。</span>
        </p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>如訂單符合退款條件，則退款至託運人於</span><span
                style='font-size:14.0pt;font-family:DFKai-SB'>BIGBOX<span lang=ZH-TW>平台上回傳之退款帳戶。</span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>如</span><span
                style='font-size:14.0pt;font-family:DFKai-SB'>BIGBOX <span lang=ZH-TW>懷疑或發現託運人使用</span>BIGBOX
<span lang=ZH-TW>平台或訂單存在欺詐行為或不法活動，</span>BIGBOX<span lang=ZH-TW>有權決定取消任何訂單或暫停或終止託運人使用</span>BIGBOX<span
                    lang=ZH-TW>平台 。</span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:28.35pt;text-indent:-28.35pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>十四、<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>運送完成：指託運貨物經運送人送達託運人指定地點，如指定地點無人收受貨物，運送人得視情況退還貨物予託運人（需加收退貨費用），或直接將貨物放置於託運人指定地點，之後該貨物產生任何風險概由託運人承受。</span>
        </p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:28.35pt;text-indent:-28.35pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>十五、<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>不便運送之貨物：</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>未依貨物性質、重量等狀況做好合適保護包裝之貨物。</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:14.0pt;font-family:DFKai-SB'>1<span
                lang=ZH-TW>個大盒子裝載重量超過</span>20<span lang=ZH-TW>公斤（</span>K601<span lang=ZH-TW>箱子尺寸：</span>58
x 41x 33 cm<span lang=ZH-TW>；容量：</span>45L<span lang=ZH-TW>）。</span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>懸掛黃色車牌及紅色車牌機車或是打檔車。</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>除機車外，大型物件單邊長度過</span><span
                style='font-size:14.0pt;font-family:DFKai-SB'>200<span lang=ZH-TW>公分，或總重量超過</span>40KG<span
                lang=ZH-TW>重之貨物。</span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>液化物品使用瓶裝、罐裝，包裝不夠堅固，不堪震盪、容易破漏者。</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW
                    style='font-size:14.0pt;font-family:DFKai-SB'>活體動物、死體動物、肉類、海鮮、疫苗、血液、檢體、冷藏物品及類似易腐敗之物品</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>冰棒、冰淇淋、奶油裝飾蛋糕、母奶及類似物品。</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>仿冒盜版品、毒品、違禁品、危險物品</span><span
                style='font-size:14.0pt;font-family:DFKai-SB'>(<span lang=ZH-TW>具強烈腐蝕性、爆炸性之物品、有毒化學藥品</span>)<span
                lang=ZH-TW>、對人體或其他託運貨物有損害危險可能之物品。</span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>運送時間無法配合運送人之貨物。</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>10.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>其他經運送人認定無法承運之貨物。</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:28.35pt;text-indent:-28.35pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>十六、<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:14.0pt;font-family:DFKai-SB'>BIGBOX<span
                lang=ZH-TW>平台除外責任項目：</span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:14.0pt;font-family:DFKai-SB'>BIGBOX<span
                lang=ZH-TW>為第三方平台提供『共享物流』之服務，僅提供託運人與運送人間之媒合，建議託運人事先和運送人確認時段取貨及收貨，若有發生任何取貨或收貨時間上的誤差或延遲，</span>BIGBOX<span
                lang=ZH-TW>恕無法提供賠償。</span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>本條款有關「不便運送之貨物」所致之損害。而「損害」包括但不限於毀損、遺失、滅失、不堪使用、功能喪失等情形。</span>
        </p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>天災或不可抗力之情事所致之損害。</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>不可歸咎於</span><span
                style='font-size:14.0pt;font-family:DFKai-SB'>BIGBOX<span lang=ZH-TW>之災害。</span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>因貨物自身性質造成之故障、腐壞、生銹、爆炸、起火等類似情形。</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>因社會運動事件、罷工、刑事案件造成之損害。</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>因法律或公權力執行所致之查扣、查封、沒收、停止運送等情事造成損害。</span>
        </p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>託運人資料填寫錯誤導致之損害。</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>其他依法令或習慣不可歸責於</span><span
                style='font-size:14.0pt;font-family:DFKai-SB'>BIGBOX<span lang=ZH-TW>之責任。</span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:28.35pt;text-indent:-28.35pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>十七、<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>運費加成、保值、賠償：</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>配送偏遠地區，或配送過程繁瑣困難之貨物，運送人可能會要求運費加成。</span>
        </p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>託運人託運之貨物，如有現金、有價證券、珠寶、貴重金屬、美術品、骨董或類似貴重物品時，除託運人於託運時記載貨物之性質及價值並辦理保值保險外，運送人對於上開貨物之毀損或滅失，不負責任。</span>
        </p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>如需保值貨物應保險並加收</span><span
                style='font-size:14.0pt;font-family:DFKai-SB'>300<span lang=ZH-TW>元保險費（保險費有變動者，以最新費用為準）。經保險人同意且已收取保險費之貨物，每件以新台幣三萬元為賠償金額上限；如貨物價值超過上述金額者，貨物應予分包託運。</span></span>
        </p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>運送貨物本體以外之其他利益或所失利益或衍生利益不予賠償。</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>未申報保值者如發生損害時，運送人賠償金額每件以不超過新台幣</span><span
                style='font-size:14.0pt;font-family:DFKai-SB'>3,000<span lang=ZH-TW>元為限，並以可歸責於運送人為限。</span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>如機車因運送過程受損，例如後照鏡、車燈破裂、輪胎少一顆等情形，如可歸責於運送人，由運送人依照折舊現況予以賠償，賠償上限適用本條款約定。</span>
        </p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>若有需要幫忙搬運，司機搭電梯上樓至門口幫忙搬運需加收</span><span
                style='font-size:14.0pt;font-family:DFKai-SB'>100<span lang=ZH-TW>元，若需爬樓梯每一層樓加收</span>200<span
                lang=ZH-TW>元。</span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:28.35pt;text-indent:-28.35pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>十八、<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>為確保貨物安全及配送效能，請注意下列相關事項：</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>下單</span><span
                style='font-size:14.0pt;font-family:DFKai-SB'>24<span lang=ZH-TW>小時內須匯款完成才會進行排單，以匯款時間作為排單根據。若超過時間匯款則須重新排單。</span></span>
        </p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>下單時間與取貨時間之間隔不得小於三天。</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>請用保護完善包裝材料</span><span
                style='font-size:14.0pt;font-family:DFKai-SB'>(<span lang=ZH-TW>如氣泡袋、泡棉、舊報紙、保麗龍、海綿</span>)<span
                lang=ZH-TW>予以保護貨物，包裝建議如下。</span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>玻璃</span><span
                style='font-size:14.0pt;font-family:DFKai-SB'>/<span lang=ZH-TW>陶瓷品：每件物品請分開包裝保護，如茶壺與壺蓋請分開。</span></span>
        </p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>電器</span><span
                style='font-size:14.0pt;font-family:DFKai-SB'>/<span lang=ZH-TW>儀器：以原廠出貨包材包裝，如原廠包材已丟棄，須以替代包材或填充泡棉、舊報紙等以減少產品晃動。</span></span>
        </p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>電腦主機及螢幕：
以原廠出貨包材包裝，如原廠包材已丟棄，須以替代包材或填充泡棉、舊報紙等以減少產品晃動。若需裸裝配送者，運送之損害由託運人自行承擔。</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>瓜類蔬果：瓜果類以舊報紙填充內箱，可避免因晃動而裂開。</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>小型配件</span><span
                style='font-size:14.0pt;font-family:DFKai-SB'>/<span lang=ZH-TW>小型物件：小型產品建議放入小紙箱，並以緩衝包材填充，以避免因產品體積小不明顯而被忽略。</span></span>
        </p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>服飾類：若使用破壞袋包裝，請於內層加放氣泡袋等，以避免因勾破而使物品掉落；為避免破壞袋外託運單脫落應使用膠帶再次黏貼。</span>
        </p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>10.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>產品裝箱請勿高過外箱高度，以免封箱時外箱高凸不平整，外箱請以專用封箱膠帶黏貼，可視情形將外箱以打包帶捆綁。</span>
        </p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>11.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>裝箱時間為</span><span
                style='font-size:14.0pt;font-family:DFKai-SB'>15<span lang=ZH-TW>分鐘（以運送人交予客戶箱子後開始計算），每超過</span>15<span
                lang=ZH-TW>分鐘需負擔</span>200<span lang=ZH-TW>元之拖延費給運送人。</span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>12.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span lang=ZH-TW
                    style='font-size:14.0pt;font-family:DFKai-SB'>交貨、收貨時間前一個小時運送人會撥打電話提醒託運人，依照原定時間等待</span><span
                style='font-size:14.0pt;font-family:DFKai-SB'>10<span lang=ZH-TW>分鐘後都無法聯絡到託運人，將視為已收貨完成，託運人不得要求退費。</span></span>
        </p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>13.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span lang=ZH-TW
                    style='font-size:14.0pt;font-family:DFKai-SB'>寄貨日期兩個禮拜前取消訂單者，全額退費；寄貨日期前一個禮拜取消訂單者，退訂單金額之</span><span
                style='font-size:14.0pt;font-family:DFKai-SB'>50%<span
                lang=ZH-TW>；寄貨日期三天前要求取消訂單者，不予退費，以上退款手續費均由託運人自行負擔。</span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>14.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='font-size:14.0pt;font-family:DFKai-SB'>BIGBOX<span
                lang=ZH-TW>擁有審核訂單是否成立之權利，若因偏遠地區無運送人願意承運，導致訂單取消，託運人不須負擔手續費。</span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>15.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>運送人例假日不休息，遇國定假日則休息，恕不提供服務。</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:28.35pt;text-indent:-28.35pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>十九、<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>權利及義務：</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:24.0pt;text-indent:-24.0pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>運送人對於因運送過程所致託運貨物之毀損、滅失或損害者，應依法負賠償責任。但運送人能證明上開情形係因不可抗力、或因託運貨物之性質，或因託運人或其代理人、使用人之過失所造成者，不在此限。</span>
        </p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:24.0pt;text-indent:-24.0pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>前項情形，託運人應於運送完成後</span><span
                style='font-size:14.0pt;font-family:DFKai-SB'>3<span lang=ZH-TW>日內通知運送人。</span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:24.0pt;text-indent:-24.0pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=ZH-TW
                    style='font-size:14.0pt;font-family:DFKai-SB'>如託運人對運送人隱瞞本條款「不便運送之貨物」，造成運送人之損害，託運人須負賠償責任。</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:24.0pt;text-indent:-24.0pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>託運人如需變更運送時間，應於登記運送日期前</span><span
                style='font-size:14.0pt;font-family:DFKai-SB'>3<span
                lang=ZH-TW>日通知運送人，如未通知，而運送人已遵照日期前往取貨時，託運人仍需支付費用。</span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:24.0pt;text-indent:-24.0pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>運送作業進行中，如因天氣、路況、災害、事故等因素導致未能如期完成運送者，由運送人與託運人另定時間繼續完成。</span>
        </p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:24.0pt;text-indent:-24.0pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>如可歸責於運送人導致未能於約定時間完成運送者，託運人得解除契約，並請求運送人返還貨物，並退還收取之費用。</span>
        </p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:24.0pt;text-indent:-24.0pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:14.0pt;font-family:DFKai-SB'>BIGBOX <span
                lang=ZH-TW>有權行使下列任何一項政策：</span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.5in;text-indent:-.5in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>(1)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=ZH-TW
                    style='font-size:14.0pt;font-family:DFKai-SB'>對平台上發佈任何不遵守本條款之內容進行調查，並自行決定刪除或要求發佈者刪除內容。</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.5in;text-indent:-.5in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>(2)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>刪除具有辱罵、非法或不遵守本條款之內容。</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.5in;text-indent:-.5in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>(3)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>使用者出現違反本條款之行為時，暫停或終止使用者使用</span><span
                style='font-size:14.0pt;font-family:DFKai-SB'>BIGBOX<span lang=ZH-TW>平台之權利。</span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.5in;text-indent:-.5in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>(4)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>檢視或編輯平台上任何內容以及編輯或刪除平台上發佈之任何內容。</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:24.0pt;text-indent:-24.0pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:14.0pt;font-family:DFKai-SB'>BIGBOX<span
                lang=ZH-TW>對於本條款擁有最終解釋及修改權利。</span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:24.0pt;text-indent:-24.0pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>七、<span style='font:7.0pt "Times New Roman"'>
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>隱私權條款</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>請詳讀本隱私權條款並於使用</span><span
                style='font-size:14.0pt;font-family:DFKai-SB'>BIGBOX<span lang=ZH-TW>服務前確定了解本條款內容。當託運人瀏覽或使用本網站提供之服務，即表示託運人同意</span>BIGBOX<span
                lang=ZH-TW>蒐集、使用與處理託運人提供的個人資料。</span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:14.0pt;font-family:DFKai-SB'>BIGBOX<span
                lang=ZH-TW>將會蒐集託運人之個人資料（包含但不限於託運人姓名、電子郵件、地址、電話號碼、帳戶資料等），但僅會在託運人自願提供時候才蒐集該資料。</span>BIGBOX<span
                lang=ZH-TW>蒐集這些資料僅會用於提供給託運人有關</span>BIGBOX<span lang=ZH-TW>平台或其他服務。</span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:14.0pt;font-family:DFKai-SB'>BIGBOX<span
                lang=ZH-TW>將會保護託運人個人資料之安全性，並保護託運人之個人資料不被盜用。如託運人通知註銷個人資料，</span>BIGBOX <span
                lang=ZH-TW>將會消除關於託運人之個人資料。</span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>託運人同意</span><span
                style='font-size:14.0pt;font-family:DFKai-SB'>BIGBOX<span lang=ZH-TW>蒐集、處理及使用託運人之個人資料，於平台進行流程及發送訂單，且其他經由平台提供的服務。</span></span>
        </p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>託運人同意</span><span
                style='font-size:14.0pt;font-family:DFKai-SB'>BIGBOX<span
                lang=ZH-TW>使用託運人的個人資料，通知託運人關於平台內容、商品、行銷資訊與服務。</span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:.25in;text-indent:-.25in;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>如有未盡之處，概依個人資料保護法之規定。</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:24.0pt;text-indent:-24.0pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>八、<span style='font:7.0pt "Times New Roman"'>
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>智慧財產權</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:24.0pt;line-height:15.0pt'><span style='font-size:14.0pt;
font-family:DFKai-SB'>BIGBOX<span lang=ZH-TW>依法所享有之商標權、專利權、著作權、營業秘密，均為</span>
BIGBOX<span lang=ZH-TW>之智慧財產權（以下稱為「智慧財產權」）。未經</span>BIGBOX<span
                    lang=ZH-TW>事前書面同意，託運人不得以任何方式使用、複製、轉載、發佈、傳輸或修改。</span></span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:24.0pt;text-indent:-24.0pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>九、<span style='font:7.0pt "Times New Roman"'>
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>違約處理：</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:24.0pt;text-indent:-24.0pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>雙方應依本條款誠信履行，如可歸責於一方未能履約而造成他方損失及損害者，他方得請求一切賠償。</span>
        </p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:24.0pt;text-indent:-24.0pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>任一方違約經他方催告後仍未履約者，他方得解除本條款並請求一切賠償。</span>
        </p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:24.0pt;text-indent:-24.0pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>十、<span style='font:7.0pt "Times New Roman"'>
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>不可抗力</span><span
                style='font-size:14.0pt;font-family:DFKai-SB'>:</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:28.35pt;text-indent:-28.35pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>本條款所稱不可抗力係指任一方或雙方因天災、地變、戰爭等類似情形導致任一方或雙方現實上無法繼續履行本條款。</span>
        </p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:28.35pt;text-indent:-28.35pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>不可抗力事件發生後，無法履約之一方應盡最大善意通知他方，如雙方協商後仍無法履行本條款，則本條款即視為終止。</span>
        </p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:28.35pt;text-indent:-28.35pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>十一、<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>本條款如涉訟，雙方同意以台灣台中地方法院為第一審管轄法院。如為託運人與運送人之糾紛而發生訴訟，概依民事訴訟法有關法院管轄之規定。</span>
        </p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:28.35pt;text-indent:-28.35pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>十二、<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>本條款如有未盡事宜，概依中華民國法律適用之。</span></p>

        <p class=MsoListParagraph style='margin-top:5.0pt;margin-right:0in;margin-bottom:
5.0pt;margin-left:28.35pt;text-indent:-28.35pt;line-height:15.0pt'><span
                style='font-size:14.0pt;font-family:DFKai-SB'>十三、<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=ZH-TW style='font-size:14.0pt;font-family:DFKai-SB'>本條款自託運人使用本網站提供之服務開始生效。</span></p>

        <p class=MsoNormal style='margin-top:2.0pt;margin-right:0in;margin-bottom:5.0pt;
margin-left:0in;text-align:justify;text-justify:inter-ideograph;line-height:
15.0pt'><span style='font-size:14.0pt;font-family:DFKai-SB'>&nbsp;</span></p>

        <p class=MsoNormal style='margin-top:2.0pt;margin-right:0in;margin-bottom:5.0pt;
margin-left:0in;text-align:justify;text-justify:inter-ideograph;line-height:
15.0pt'><span style='font-size:14.0pt;font-family:DFKai-SB'>&nbsp;</span></p>

    </div>
</template>

<script>
    export default {
        name: "Agreement"
    }
</script>

<style scoped>
    @font-face {
        font-family: PMingLiU;
        panose-1: 2 2 5 0 0 0 0 0 0 0;
    }

    @font-face {
        font-family: "Cambria Math";
        panose-1: 2 4 5 3 5 4 6 3 2 4;
    }

    @font-face {
        font-family: Calibri;
        panose-1: 2 15 5 2 2 2 4 3 2 4;
    }

    @font-face {
        font-family: DFKai-SB;
    }

    @font-face {
        font-family: "\@PMingLiU";
        panose-1: 2 1 6 1 0 1 1 1 1 1;
    }

    @font-face {
        font-family: "\@DFKai-SB";
    }

    /* Style Definitions */
    p.MsoNormal, li.MsoNormal, div.MsoNormal {
        margin: 0in;
        font-size: 12.0pt;
        font-family: "Calibri", sans-serif;
    }

    p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph {
        margin-top: 0in;
        margin-right: 0in;
        margin-bottom: 0in;
        margin-left: 24.0pt;
        font-size: 12.0pt;
        font-family: "Calibri", sans-serif;
    }

    .MsoChpDefault {
        font-size: 12.0pt;
        font-family: "Calibri", sans-serif;
    }

    /* Page Definitions */
    @page WordSection1 {
        size: 595.3pt 841.9pt;
        margin: 1.0in 1.25in 1.0in 1.25in;
        layout-grid: 18.0pt;
    }

    div.WordSection1 {
        page: WordSection1;
    }

    /* List Definitions */
    ol {
        margin-bottom: 0in;
    }

    ul {
        margin-bottom: 0in;
    }
</style>